import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import Link from "next/link";
import React from "react";
import { useIntl } from "react-intl";
import { Button } from "@/components/ui";
import { BlikLogo, Clock, InPostLogo, PayPoLogo, PayuLogo, ShoppingBag, Truck } from "@/components/ui/Icon/$";
import { FREE_DELIVERY_PRICE, PRODUCT_VARIANT_SHOW_DELIVERY_TIME_METHOD_ATTRIBUTE } from "@/config/common.config";
import { getAttribute } from "@/helpers/attribute.helper";
import useCheckout from "@/hooks/useCheckout.hook";
import { useGlobal } from "@/hooks/useGlobal.hook";
import usePath from "@/hooks/usePath.hook";
import useRegion from "@/hooks/useRegion.hook";
import styles from "./Footer.styles";

var Footer = function Footer() {
  var path = usePath();
  var t = useIntl();

  var _useRegion = useRegion(),
      formatPrice = _useRegion.formatPrice,
      currentLocale = _useRegion.currentLocale,
      currentChannel = _useRegion.currentChannel;

  var _useGlobal = useGlobal(),
      shippingMethods = _useGlobal.shippingMethods;

  var _useCheckout = useCheckout(),
      checkout = _useCheckout.checkout,
      loading = _useCheckout.loading,
      submitting = _useCheckout.submitting;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showDeliveryTime = _React$useState2[0],
      setShowDeliveryTime = _React$useState2[1];

  React.useEffect(function () {
    var filteredCheckoutLines = (checkout === null || checkout === void 0 ? void 0 : checkout.lines.filter(function (line) {
      return line.variant.product.productType.isShippingRequired && !line.variant.product.productType.isDigital;
    })) || [];
    var result = filteredCheckoutLines.every(function (line) {
      var _getAttribute;

      return !!((_getAttribute = getAttribute(PRODUCT_VARIANT_SHOW_DELIVERY_TIME_METHOD_ATTRIBUTE, line.variant.attributes)) !== null && _getAttribute !== void 0 && _getAttribute.value[0]);
    });
    setShowDeliveryTime(result);
  }, [checkout === null || checkout === void 0 ? void 0 : checkout.lines]);
  var cheapestDeliveryAmount = React.useMemo(function () {
    var _checkout$subtotalPri, _checkout$subtotalPri2;

    var shippingMethod = shippingMethods.filter(function (method) {
      return method.price.amount > 0;
    }).sort(function (a, b) {
      return a.price.amount - b.price.amount;
    })[0];

    if (!shippingMethod) {
      return {
        amount: 0,
        currency: currentChannel.currencyCode
      };
    }

    var freeDeliveryPriceAmount = FREE_DELIVERY_PRICE[currentLocale];
    var productsPriceAmount = (_checkout$subtotalPri = checkout === null || checkout === void 0 || (_checkout$subtotalPri2 = checkout.subtotalPrice) === null || _checkout$subtotalPri2 === void 0 ? void 0 : _checkout$subtotalPri2.gross.amount) !== null && _checkout$subtotalPri !== void 0 ? _checkout$subtotalPri : 0;
    return productsPriceAmount < freeDeliveryPriceAmount ? shippingMethod.price : {
      amount: 0,
      currency: shippingMethod.price.currency
    };
  }, [shippingMethods, checkout === null || checkout === void 0 ? void 0 : checkout.subtotalPrice, currentLocale, currentChannel]);
  return __jsx("div", {
    className: styles.root()
  }, __jsx("div", {
    className: styles.block({
      tall: true
    })
  }, __jsx("div", {
    className: styles.orderInfoWrapper()
  }, __jsx("div", {
    className: styles.orderInfo()
  }, shippingMethods.length > 0 ? __jsx("div", {
    className: styles.orderInfoItem()
  }, __jsx(Truck, {
    className: styles.infoItemIcon({
      variant: "success"
    })
  }), __jsx("span", {
    className: styles.infoItemText({
      variant: "success"
    })
  }, cheapestDeliveryAmount.amount === 0 ? formatPrice(cheapestDeliveryAmount, {
    trailingZeroDisplay: "stripIfInteger"
  }) : t.formatMessage({
    id: "cart.footer.cheapestDelivery.nonZero",
    defaultMessage: "Od {price}"
  }, {
    price: formatPrice(cheapestDeliveryAmount)
  }))) : null, showDeliveryTime ? __jsx("div", {
    className: styles.orderInfoItem()
  }, __jsx(Clock, {
    className: styles.infoItemIcon({
      variant: "success"
    })
  }), __jsx("span", {
    className: styles.infoItemText({
      variant: "success"
    })
  }, t.formatMessage({
    id: "cart.footer.orderDetails.shipment",
    defaultMessage: "Shipping in 24h"
  }))) : null, __jsx("div", {
    className: styles.orderInfoItem()
  }, __jsx(ShoppingBag, {
    className: styles.infoItemIcon()
  }), __jsx("span", {
    className: styles.infoItemText()
  }, t.formatMessage({
    id: "cart.footer.orderDetails.return",
    defaultMessage: "Free return within 14 days"
  }))))), __jsx(Link, {
    href: path.checkout.$url(),
    passHref: true,
    legacyBehavior: true
  }, __jsx(Button, {
    disabled: !checkout || (checkout === null || checkout === void 0 ? void 0 : checkout.lines.length) === 0 || loading || submitting,
    color: "flavor",
    variant: "oval",
    width: "100%",
    "data-testid": "cart-footer-button"
  }, t.formatMessage({
    defaultMessage: "Proceed to checkout",
    id: "cart.footer.button"
  } // {
  //   price: formatPrice(checkout?.totalPrice.gross),
  // },
  ))), __jsx("div", {
    className: styles.providers()
  }, __jsx(InPostLogo, {
    width: 60,
    height: 35
  }), __jsx(BlikLogo, {
    width: 41,
    height: 19
  }), __jsx(PayuLogo, {
    width: 45,
    height: 23
  }), __jsx(PayPoLogo, {
    width: 67,
    height: 18
  }))));
};

Footer.displayName = "Footer";
export default Footer;