var __jsx = React.createElement;
import React from "react";
import { useIntl } from "react-intl";
import { Skeleton, Spacer } from "@/components/ui";
import { VoucherMetadataKey } from "@/helpers/metadata.helpers";
import useCheckout from "@/hooks/useCheckout.hook";
import styles from "./PromoProgress.styles";

var PromoProgress = function PromoProgress(_ref) {
  var withLoader = _ref.withLoader;
  var t = useIntl();

  var _useCheckout = useCheckout(),
      loading = _useCheckout.loading,
      submitting = _useCheckout.submitting,
      publicPromoCodes = _useCheckout.publicPromoCodes,
      checkout = _useCheckout.checkout,
      autoApplySubmitting = _useCheckout.autoApplySubmitting;

  var activeCode = checkout === null || checkout === void 0 ? void 0 : checkout.voucherCode;
  var config = React.useMemo(function () {
    return {
      rule: "quantity",
      message: t.formatMessage({
        id: "cart.promoProgress.message",
        defaultMessage: "Add product and get {postscript}"
      }, {
        postscript: __jsx("span", null, t.formatMessage({
          id: "cart.promoProgress.step.caption",
          defaultMessage: "{value} discount"
        }, {
          value: "5%"
        }))
      }),
      steps: [{
        code: "5%ZNIŻKI",
        message: t.formatMessage({
          id: "cart.promoProgress.step.message",
          defaultMessage: "Discount {value}. Add another product and get {postscript}"
        }, {
          value: "5%",
          postscript: __jsx("span", null, t.formatMessage({
            id: "cart.promoProgress.step.caption",
            defaultMessage: "{value} discount"
          }, {
            value: "10%"
          }))
        }),
        caption: t.formatMessage({
          id: "cart.promoProgress.step.caption",
          defaultMessage: "{value} discount"
        }, {
          value: "5%"
        })
      }, {
        code: "10%ZNIŻKI",
        message: t.formatMessage({
          id: "cart.promoProgress.step.message",
          defaultMessage: "Discount {value}. Add another product and get {postscript}"
        }, {
          value: "10%",
          postscript: __jsx("span", null, t.formatMessage({
            id: "cart.promoProgress.step.caption",
            defaultMessage: "{value} discount"
          }, {
            value: "15%"
          }))
        }),
        caption: t.formatMessage({
          id: "cart.promoProgress.step.caption",
          defaultMessage: "{value} discount"
        }, {
          value: "10%"
        })
      }, {
        code: "15%ZNIŻKI",
        message: t.formatMessage({
          id: "cart.promoProgress.step.message.last",
          defaultMessage: "{value} discount applied"
        }, {
          value: "15%"
        }),
        caption: t.formatMessage({
          id: "cart.promoProgress.step.caption",
          defaultMessage: "{value} discount"
        }, {
          value: "15%"
        })
      }]
    };
  }, [t]);
  var isPromoConfigValid = React.useMemo(function () {
    if (!config) return false;
    var rule = config.rule,
        steps = config.steps;
    if (!(publicPromoCodes !== null && publicPromoCodes !== void 0 && publicPromoCodes.length)) return false;
    var matchRule = publicPromoCodes.filter(function (code) {
      var _code$metadata$find;

      return ((_code$metadata$find = code.metadata.find(function (meta) {
        return meta.key === VoucherMetadataKey.AUTO_APPLY_BY;
      })) === null || _code$metadata$find === void 0 ? void 0 : _code$metadata$find.value) === rule;
    });
    if (!matchRule.length) return false;
    var matchCodes = matchRule.filter(function (voucher) {
      return steps.map(function (step) {
        return step.code;
      }).includes(voucher.code);
    });
    if (matchCodes.length !== config.steps.length) return false;
    if (activeCode && !matchCodes.find(function (voucher) {
      return voucher.code === activeCode;
    })) return false;
    return true;
  }, [config, publicPromoCodes, activeCode]);
  var message = React.useMemo(function () {
    var _config$steps$find$me, _config$steps$find;

    return (_config$steps$find$me = (_config$steps$find = config.steps.find(function (step) {
      return step.code === activeCode;
    })) === null || _config$steps$find === void 0 ? void 0 : _config$steps$find.message) !== null && _config$steps$find$me !== void 0 ? _config$steps$find$me : config.message;
  }, [activeCode, config]);
  if (!isPromoConfigValid) return null;
  return __jsx("div", {
    className: styles.root()
  }, __jsx("div", {
    className: styles.header()
  }, withLoader && (loading || submitting || autoApplySubmitting) ? __jsx(Skeleton, {
    width: "100%",
    height: 8
  }) : __jsx("p", {
    className: styles.message()
  }, message)), __jsx(Spacer, {
    height: 8
  }), __jsx("div", {
    className: styles.container()
  }, config.steps.map(function (step, index) {
    var currentStep = config.steps.findIndex(function (s) {
      return s.code === activeCode;
    });
    var completed = index < currentStep;
    var active = index === currentStep;
    return __jsx("div", {
      className: styles.item(),
      key: step.code
    }, __jsx("div", {
      className: styles.bar({
        active: active,
        completed: completed,
        loading: loading || submitting
      })
    }), __jsx("p", {
      className: styles.caption({
        active: active,
        completed: completed
      })
    }, step.caption));
  })));
};

PromoProgress.displayName = "PromoProgress";
export default PromoProgress;