import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "display": "flex",
    "flexDirection": "column",
    "> :not([hidden]) ~ :not([hidden])": {
      "--tw-space-y-reverse": "0",
      "marginTop": "calc(0.5rem * calc(1 - var(--tw-space-y-reverse)))",
      "marginBottom": "calc(0.5rem * var(--tw-space-y-reverse))"
    },
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingTop": "1rem",
    "paddingBottom": "1rem"
  }), {}, {
    borderTop: "1px solid $gray2"
  })),
  header: _css(_objectSpread({}, {
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "space-between"
  })),
  title: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "fontWeight": "500"
  }), {}, {
    color: "$gray8",
    fontSize: "$3"
  })),
  description: _css({
    color: "$gray6"
  })
};
export default styles;