import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "position": "relative"
  }), {}, {
    backgroundColor: "$white",
    borderTop: "1px solid $gray2"
  })),
  block: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "display": "flex",
    "flexDirection": "column",
    "> :not([hidden]) ~ :not([hidden])": {
      "--tw-space-y-reverse": "0",
      "marginTop": "calc(0.5rem * calc(1 - var(--tw-space-y-reverse)))",
      "marginBottom": "calc(0.5rem * var(--tw-space-y-reverse))"
    },
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingTop": "1rem",
    "paddingBottom": "1rem"
  }), {}, {
    variants: {
      tall: {
        "true": _objectSpread({}, {
          "> :not([hidden]) ~ :not([hidden])": {
            "--tw-space-y-reverse": "0",
            "marginTop": "calc(1rem * calc(1 - var(--tw-space-y-reverse)))",
            "marginBottom": "calc(1rem * var(--tw-space-y-reverse))"
          }
        })
      }
    }
  })),
  header: _css(_objectSpread({}, {
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "space-between"
  })),
  title: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "fontSize": "1rem",
    "lineHeight": "1.5rem",
    "fontWeight": "500"
  }), {}, {
    color: "$gray8"
  })),
  orderInfoWrapper: _css(_objectSpread({}, {
    "position": "relative"
  })),
  orderInfo: _css(_objectSpread({}, {
    "scrollbarWidth": "none",
    "MsOverflowStyle": "none",
    "&::-webkit-scrollbar": {
      "display": "none"
    },
    "position": "relative",
    "display": "flex",
    "width": "100%",
    "flexWrap": "nowrap",
    "alignItems": "center",
    "justifyContent": "center",
    "gap": "0.5rem",
    "overflowX": "scroll"
  })),
  orderInfoItem: _css(_objectSpread({}, {
    "display": "flex",
    "flexShrink": "0",
    "alignItems": "center",
    "gap": "0.375rem"
  })),
  infoItemIcon: _css(_objectSpread(_objectSpread({}, {
    "height": "0.75rem",
    "width": "0.75rem"
  }), {}, {
    "@media screen and (min-width: 400px)": _objectSpread({}, {
      "height": "1rem",
      "width": "1rem"
    }),
    circle: {
      strokeWidth: "1.2",
      stroke: "$gray7"
    },
    path: {
      strokeWidth: "1.2",
      stroke: "$gray7"
    },
    variants: {
      variant: {
        success: {
          circle: {
            stroke: "$success"
          },
          path: {
            stroke: "$success"
          }
        }
      }
    }
  })),
  infoItemText: _css({
    color: "$gray7",
    fontSize: "0.75rem",
    "@media screen and (min-width: 400px)": {
      fontSize: "0.85rem"
    },
    variants: {
      variant: {
        success: {
          color: "$success"
        }
      }
    }
  }),
  providers: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center",
    "gap": "1.5rem"
  }), {}, {
    "@xs": _objectSpread({}, {
      "gap": "2.5rem"
    })
  }))
};
export default styles;