var __jsx = React.createElement;
import React from "react";
import { useIntl } from "react-intl";
import { Price } from "@/components/ui";
import { FREE_DELIVERY_PRICE } from "@/config/common.config";
import useCheckout from "@/hooks/useCheckout.hook";
import { useGlobal } from "@/hooks/useGlobal.hook";
import useRegion from "@/hooks/useRegion.hook";
import styles from "./Summary.styles";

var Summary = function Summary() {
  var _checkout$shippingPri, _checkout$deliveryMet, _checkout$giftCards$;

  var t = useIntl();

  var _useCheckout = useCheckout(),
      checkout = _useCheckout.checkout;

  var _useGlobal = useGlobal(),
      shippingMethods = _useGlobal.shippingMethods;

  var _useRegion = useRegion(),
      formatPrice = _useRegion.formatPrice,
      currentChannel = _useRegion.currentChannel,
      currentLocale = _useRegion.currentLocale;

  var delivery = checkout === null || checkout === void 0 || (_checkout$shippingPri = checkout.shippingPrice) === null || _checkout$shippingPri === void 0 ? void 0 : _checkout$shippingPri.gross;
  var showDelivery = !!(checkout !== null && checkout !== void 0 && (_checkout$deliveryMet = checkout.deliveryMethod) !== null && _checkout$deliveryMet !== void 0 && _checkout$deliveryMet.id);
  var giftCardCode = checkout === null || checkout === void 0 || (_checkout$giftCards$ = checkout.giftCards[0]) === null || _checkout$giftCards$ === void 0 ? void 0 : _checkout$giftCards$.code;
  var giftCardDiscountPrice = React.useMemo(function () {
    return checkout !== null && checkout !== void 0 && checkout.giftCards.length ? (checkout === null || checkout === void 0 ? void 0 : checkout.subtotalPrice.gross.amount) - (checkout === null || checkout === void 0 ? void 0 : checkout.totalPrice.gross.amount) + ((delivery === null || delivery === void 0 ? void 0 : delivery.amount) || 0) : 0;
  }, [checkout, delivery]);
  var subtotalDiscounted = React.useMemo(function () {
    return checkout === null || checkout === void 0 ? void 0 : checkout.lines.reduce(function (acc, line) {
      var _line$totalPrice;

      var lineTotalPrice = (_line$totalPrice = line.totalPrice) === null || _line$totalPrice === void 0 ? void 0 : _line$totalPrice.gross;

      if (lineTotalPrice) {
        return {
          currency: lineTotalPrice.currency,
          amount: acc.amount + lineTotalPrice.amount
        };
      }

      return acc;
    }, {
      currency: currentChannel.currencyCode,
      amount: 0
    });
  }, [checkout, currentChannel]);
  var subtotalUndiscounted = React.useMemo(function () {
    return checkout === null || checkout === void 0 ? void 0 : checkout.lines.reduce(function (acc, line) {
      return {
        currency: line.undiscountedTotalPrice.currency,
        amount: acc.amount + line.undiscountedTotalPrice.amount
      };
    }, {
      currency: currentChannel.currencyCode,
      amount: 0
    });
  }, [checkout, currentChannel]);
  var cheapestDeliveryAmount = React.useMemo(function () {
    var _checkout$subtotalPri, _checkout$subtotalPri2;

    var shippingMethod = shippingMethods.filter(function (method) {
      return method.price.amount > 0;
    }).sort(function (a, b) {
      return a.price.amount - b.price.amount;
    })[0];

    if (!shippingMethod) {
      return {
        amount: 0,
        currency: currentChannel.currencyCode
      };
    }

    var freeDeliveryPriceAmount = FREE_DELIVERY_PRICE[currentLocale];
    var productsPriceAmount = (_checkout$subtotalPri = checkout === null || checkout === void 0 || (_checkout$subtotalPri2 = checkout.subtotalPrice) === null || _checkout$subtotalPri2 === void 0 ? void 0 : _checkout$subtotalPri2.gross.amount) !== null && _checkout$subtotalPri !== void 0 ? _checkout$subtotalPri : 0;
    return productsPriceAmount < freeDeliveryPriceAmount ? shippingMethod.price : {
      amount: 0,
      currency: shippingMethod.price.currency
    };
  }, [shippingMethods, checkout === null || checkout === void 0 ? void 0 : checkout.subtotalPrice, currentLocale, currentChannel]);
  var totalUndiscounted = React.useMemo(function () {
    var _cheapestDeliveryAmou;

    if (!subtotalUndiscounted) {
      return {
        currency: currentChannel.currencyCode,
        amount: 0
      };
    }

    return {
      currency: subtotalUndiscounted.currency,
      amount: delivery && showDelivery ? subtotalUndiscounted.amount + delivery.amount : subtotalUndiscounted.amount + ((_cheapestDeliveryAmou = cheapestDeliveryAmount === null || cheapestDeliveryAmount === void 0 ? void 0 : cheapestDeliveryAmount.amount) !== null && _cheapestDeliveryAmou !== void 0 ? _cheapestDeliveryAmou : 0)
    };
  }, [subtotalUndiscounted, delivery, showDelivery, cheapestDeliveryAmount, currentChannel.currencyCode]);
  var total = React.useMemo(function () {
    var _cheapestDeliveryAmou2;

    if (!(checkout !== null && checkout !== void 0 && checkout.totalPrice)) {
      return {
        currency: currentChannel.currencyCode,
        amount: 0
      };
    }

    return {
      currency: currentChannel.currencyCode,
      amount: showDelivery ? checkout.totalPrice.gross.amount : checkout.totalPrice.gross.amount + ((_cheapestDeliveryAmou2 = cheapestDeliveryAmount === null || cheapestDeliveryAmount === void 0 ? void 0 : cheapestDeliveryAmount.amount) !== null && _cheapestDeliveryAmou2 !== void 0 ? _cheapestDeliveryAmou2 : 0)
    };
  }, [cheapestDeliveryAmount, checkout === null || checkout === void 0 ? void 0 : checkout.totalPrice, currentChannel, showDelivery]);
  return __jsx("div", {
    className: styles.root()
  }, __jsx("div", {
    className: styles.content()
  }, __jsx("span", {
    className: styles.title()
  }, t.formatMessage({
    defaultMessage: "Your order",
    id: "checkout.summary.title"
  })), __jsx("div", {
    className: styles.summary()
  }, __jsx("div", {
    className: styles.row()
  }, __jsx("span", {
    className: styles.label()
  }, t.formatMessage({
    defaultMessage: "Subtotal",
    id: "common.subtotal"
  })), subtotalDiscounted ? __jsx(Price, {
    priceUndiscounted: subtotalUndiscounted,
    price: subtotalDiscounted,
    discountedPriceColor: "black",
    withPercentLabel: false
  }) : null), __jsx("div", {
    className: styles.row()
  }, __jsx("span", {
    className: styles.label()
  }, t.formatMessage({
    defaultMessage: "Delivery",
    id: "common.delivery"
  })), __jsx("span", {
    className: styles.value()
  }, showDelivery ? formatPrice(delivery) : cheapestDeliveryAmount.amount === 0 ? formatPrice(cheapestDeliveryAmount) : t.formatMessage({
    id: "cart.summary.cheapestDelivery.nonZero",
    defaultMessage: "Od {price}"
  }, {
    price: formatPrice(cheapestDeliveryAmount)
  }))), giftCardDiscountPrice && giftCardCode ? __jsx("div", {
    className: styles.row()
  }, __jsx("span", {
    className: styles.label()
  }, t.formatMessage({
    defaultMessage: "Gift card",
    id: "common.giftCard"
  })), __jsx(Price, {
    className: styles.price(),
    negative: true,
    price: {
      amount: giftCardDiscountPrice,
      currency: checkout === null || checkout === void 0 ? void 0 : checkout.totalPrice.currency
    },
    discountedPriceColor: "black",
    size: "sm",
    withPercentLabel: false
  })) : null, __jsx("div", {
    className: styles.row()
  }, __jsx("span", {
    className: styles.label({
      highlight: true
    })
  }, t.formatMessage({
    defaultMessage: "Total",
    id: "common.total"
  })), total ? __jsx(Price, {
    priceUndiscounted: totalUndiscounted,
    price: total,
    discountedPriceColor: "black",
    withPercentLabel: false,
    size: "sm",
    bold: true
  }) : null))));
};

Summary.displayName = "Summary";
export default Summary;