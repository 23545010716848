import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingBottom": "0.5rem",
    "paddingTop": "0.375rem"
  }), {}, {
    borderTop: "1px solid $gray2",
    borderBottom: "1px solid $gray2",
    backgroundColor: "$gray0"
  })),
  message: _css({
    fontSize: "0.8125rem",
    color: "$gray7"
  }),
  messageFree: _css({
    color: "$successLight",
    fontWeight: "$bold"
  }),
  messagePrice: _css({
    color: "$default",
    fontWeight: "$bold"
  }),
  progressContainer: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "marginTop": "0.5rem",
    "height": "0.5rem",
    "width": "100%",
    "overflow": "hidden",
    "borderRadius": "0.375rem"
  }), {}, {
    backgroundColor: "$gray3"
  })),
  progressBar: _css(_objectSpread(_objectSpread({}, {
    "position": "absolute",
    "inset": "0px",
    "height": "100%",
    "transitionProperty": "all",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "500ms",
    "animationDuration": "500ms"
  }), {}, {
    variants: {
      full: {
        "true": {
          backgroundColor: "$successLight"
        },
        "false": {
          backgroundColor: "$default"
        }
      },
      loading: {
        "true": _objectSpread({}, {
          "animation": "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite"
        })
      }
    }
  }))
};
export default styles;