import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import * as Sentry from "@sentry/nextjs";
import React from "react";
import { useIntl } from "react-intl";
import { PromoProduct } from "@/cart/components/PromoProducts/components";
import { VoucherMetadataKey } from "~/src/helpers/metadata.helpers";
import useCheckout from "~/src/hooks/useCheckout.hook";
import styles from "./PromoProducts.styles";

var PromoProducts = function PromoProducts(_ref) {
  var proposedSetIsVisible = _ref.proposedSetIsVisible,
      handleSetProposedSetIsVisible = _ref.handleSetProposedSetIsVisible;
  var t = useIntl();

  var _useCheckout = useCheckout(),
      checkout = _useCheckout.checkout,
      publicPromoCodes = _useCheckout.publicPromoCodes,
      activePublicPromoCode = _useCheckout.activePublicPromoCode;

  var _React$useState = React.useState([]),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      promoCodes = _React$useState2[0],
      setPromoCodes = _React$useState2[1];

  React.useEffect(function () {
    if (!(checkout !== null && checkout !== void 0 && checkout.lines.length)) return;
    if (!publicPromoCodes || publicPromoCodes.length === 0) return;
    var properlyCodes = publicPromoCodes.filter(function (publicPromoCode) {
      var _publicPromoCode$meta, _publicPromoCode$prod;

      var hasApplyRules = !!((_publicPromoCode$meta = publicPromoCode.metadata.find(function (meta) {
        return meta.key === VoucherMetadataKey.APPLY_RULES;
      })) !== null && _publicPromoCode$meta !== void 0 && _publicPromoCode$meta.value);
      if (!hasApplyRules || publicPromoCode.canBeAutoApplied) return false;
      var allProductsInCheckout = (_publicPromoCode$prod = publicPromoCode.products) === null || _publicPromoCode$prod === void 0 ? void 0 : _publicPromoCode$prod.edges.every(function (productEdge) {
        var productVariant = productEdge.node.defaultVariant;
        return productVariant && !!(checkout !== null && checkout !== void 0 && checkout.lines.find(function (line) {
          return line.variant.id === productVariant.id;
        }));
      });
      var promoCodeAlreadyInCart = (checkout === null || checkout === void 0 ? void 0 : checkout.voucherCode) === publicPromoCode.code;
      return !(allProductsInCheckout && promoCodeAlreadyInCart);
    });
    var codesToApply = properlyCodes.filter(function (properlyCode) {
      var applyRules = [];

      try {
        var _properlyCode$metadat;

        applyRules = JSON.parse((_properlyCode$metadat = properlyCode.metadata.find(function (meta) {
          return meta.key === VoucherMetadataKey.APPLY_RULES;
        })) === null || _properlyCode$metadat === void 0 ? void 0 : _properlyCode$metadat.value);
      } catch (e) {
        Sentry.captureException(new Error("Error parsing \"applyRules\" promo code: ".concat(properlyCode.code)));
      }

      var isMatching = false;
      applyRules.forEach(function (rule) {
        checkout.lines.forEach(function (line) {
          if (line.variant.product.slug === rule.productSlug && line.quantity >= parseInt(rule.quantity)) {
            isMatching = true;
          }
        });
      });
      return isMatching && properlyCode.products && properlyCode.products.totalCount;
    });
    setPromoCodes(codesToApply);
  }, [checkout === null || checkout === void 0 ? void 0 : checkout.lines, checkout === null || checkout === void 0 ? void 0 : checkout.voucherCode, publicPromoCodes]);
  React.useEffect(function () {
    if (!proposedSetIsVisible) {
      if (!promoCodes.length && !activePublicPromoCode) {
        handleSetProposedSetIsVisible(true);
      }
    } else {
      var _activePublicPromoCod;

      var activePublicPromoCodeHasApplyRules = !!(activePublicPromoCode !== null && activePublicPromoCode !== void 0 && (_activePublicPromoCod = activePublicPromoCode.metadata.find(function (meta) {
        return meta.key === VoucherMetadataKey.APPLY_RULES;
      })) !== null && _activePublicPromoCod !== void 0 && _activePublicPromoCod.value);

      if (promoCodes.length || activePublicPromoCodeHasApplyRules) {
        // hide proposed set if promo codes (codes with apply rules) are available or active promo code has apply rules
        handleSetProposedSetIsVisible(false);
      }
    }
  }, [promoCodes, activePublicPromoCode, proposedSetIsVisible, handleSetProposedSetIsVisible]);
  if (!promoCodes.length) return null;
  return __jsx("div", {
    className: styles.root()
  }, __jsx("p", null, t.formatMessage({
    id: "cart.promoProducts.title",
    defaultMessage: "Special offer"
  })), promoCodes.map(function (promoCode, promoCodeIndes) {
    var _promoCode$products;

    return (_promoCode$products = promoCode.products) === null || _promoCode$products === void 0 ? void 0 : _promoCode$products.edges.map(function (productEdge, productEdgeIndex) {
      var productVariant = productEdge.node.defaultVariant;
      return productVariant ? __jsx(PromoProduct, {
        productVariant: productVariant,
        discountValueType: promoCode.discountValueType,
        discountValue: promoCode.discountValue,
        code: promoCode.code,
        key: "promo-code-".concat(promoCodeIndes, "-product").concat(productEdgeIndex)
      }) : null;
    });
  }));
};

PromoProducts.displayName = "PromoProducts";
export default PromoProducts;