var __jsx = React.createElement;
import React from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { PromoCodeForm } from "@/checkout/components";
import styles from "./PromoCode.styles";

var PromoCode = function PromoCode(_ref) {
  var onSubmit = _ref.onSubmit,
      onRemove = _ref.onRemove,
      _ref$showInfoInsteadF = _ref.showInfoInsteadForm,
      showInfoInsteadForm = _ref$showInfoInsteadF === void 0 ? false : _ref$showInfoInsteadF;
  var t = useIntl();

  var _useFormContext = useFormContext(),
      watch = _useFormContext.watch,
      setValue = _useFormContext.setValue;

  var promoCode = watch("promoCode");
  React.useEffect(function () {
    if (promoCode) {
      var promoCodeUpperCase = promoCode.toUpperCase();

      if (promoCodeUpperCase !== promoCode) {
        setValue("promoCode", promoCodeUpperCase);
      }
    }
  }, [promoCode, setValue]);
  return __jsx("div", {
    className: styles.root()
  }, showInfoInsteadForm ? __jsx(React.Fragment, null, __jsx("div", {
    className: styles.header()
  }, __jsx("h2", {
    className: styles.title()
  }, t.formatMessage({
    defaultMessage: "Do you have a promotional code?",
    id: "promoCode.infoTitle"
  }))), __jsx("p", {
    className: styles.description()
  }, t.formatMessage({
    defaultMessage: "Provide it in the second step of the order summary.",
    id: "promoCode.infoDescription"
  }))) : __jsx(React.Fragment, null, __jsx("div", {
    className: styles.header()
  }, __jsx("h2", {
    className: styles.title()
  }, t.formatMessage({
    defaultMessage: "Promotion code / Gift card",
    id: "promoCode.title"
  }))), __jsx(PromoCodeForm, {
    onSubmit: onSubmit,
    onRemove: onRemove
  })));
};

export default PromoCode;