var __jsx = React.createElement;
import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useIntl } from "react-intl";
import { Button } from "@/components/ui";
import useCheckout from "@/hooks/useCheckout.hook";
import styles from "./Header.styles";

var Header = function Header(_ref) {
  var closeAction = _ref.closeAction,
      disabled = _ref.disabled;

  var _useCheckout = useCheckout(),
      submitting = _useCheckout.submitting;

  var t = useIntl();
  return __jsx("div", {
    className: styles.header()
  }, __jsx("h4", {
    className: styles.title()
  }, t.formatMessage({
    defaultMessage: "Your cart",
    id: "cart.header.title"
  })), __jsx(Button, {
    "aria-label": t.formatMessage({
      id: "common.close",
      defaultMessage: "close"
    }),
    disabled: disabled || submitting,
    onClick: closeAction,
    variant: "naked",
    color: "dark"
  }, __jsx(XMarkIcon, {
    width: 28,
    height: 28
  })));
};

Header.displayName = "Header";
export default Header;