var __jsx = React.createElement;
import React from "react";
import { useIntl } from "react-intl";
import { FREE_DELIVERY_PRICE } from "@/config/common.config";
import useCheckout from "@/hooks/useCheckout.hook";
import useRegion from "@/hooks/useRegion.hook";
import styles from "./FreeDelivery.styles";

var FreeDelivery = function FreeDelivery() {
  var _checkout$subtotalPri, _checkout$subtotalPri2, _checkout$subtotalPri3, _checkout$subtotalPri4;

  var t = useIntl();

  var _useRegion = useRegion(),
      formatPrice = _useRegion.formatPrice,
      currentChannel = _useRegion.currentChannel,
      currentLocale = _useRegion.currentLocale;

  var _useCheckout = useCheckout(),
      checkout = _useCheckout.checkout,
      loading = _useCheckout.loading,
      submitting = _useCheckout.submitting;

  var freeDeliveryPriceAmount = FREE_DELIVERY_PRICE[currentLocale];
  var productsPriceAmount = (_checkout$subtotalPri = checkout === null || checkout === void 0 || (_checkout$subtotalPri2 = checkout.subtotalPrice) === null || _checkout$subtotalPri2 === void 0 ? void 0 : _checkout$subtotalPri2.gross.amount) !== null && _checkout$subtotalPri !== void 0 ? _checkout$subtotalPri : 0;
  var remainingPriceAmount = productsPriceAmount < freeDeliveryPriceAmount ? freeDeliveryPriceAmount - productsPriceAmount : 0;
  var remainingPrice = formatPrice({
    amount: remainingPriceAmount !== null && remainingPriceAmount !== void 0 ? remainingPriceAmount : 0,
    currency: (_checkout$subtotalPri3 = checkout === null || checkout === void 0 || (_checkout$subtotalPri4 = checkout.subtotalPrice) === null || _checkout$subtotalPri4 === void 0 ? void 0 : _checkout$subtotalPri4.gross.currency) !== null && _checkout$subtotalPri3 !== void 0 ? _checkout$subtotalPri3 : currentChannel.currencyCode
  });
  var progressBarPercentValue = remainingPriceAmount > 0 ? productsPriceAmount / freeDeliveryPriceAmount * 100 : 100;
  var message = progressBarPercentValue === 100 ? t.formatMessage({
    id: "cart.freeDelivery.messageFree",
    defaultMessage: "We will deliver this order to you {postscript}"
  }, {
    postscript: __jsx("span", {
      className: styles.messageFree()
    }, t.formatMessage({
      id: "cart.freeDelivery.messageFreePostscript",
      defaultMessage: "for free!"
    }))
  }) : t.formatMessage({
    id: "cart.freeDelivery.messageRemaining",
    defaultMessage: "You are {price} short of free shipping."
  }, {
    price: __jsx("span", {
      className: styles.messagePrice()
    }, remainingPrice)
  });
  return __jsx("div", {
    className: styles.root()
  }, __jsx("span", {
    className: styles.message()
  }, message), __jsx("div", {
    className: styles.progressContainer()
  }, __jsx("div", {
    className: styles.progressBar({
      full: progressBarPercentValue === 100,
      loading: loading || submitting
    }),
    style: {
      width: "".concat(progressBarPercentValue, "%")
    }
  })));
};

export default FreeDelivery;