import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingTop": "0.375rem",
    "paddingBottom": "0.375rem"
  }), {}, {
    borderTop: "1px solid $gray2",
    borderBottom: "1px solid $gray2",
    backgroundColor: "$gray0"
  })),
  message: _css({
    fontSize: "0.8125rem",
    color: "$gray7",
    "& span": {
      color: "$default",
      fontWeight: "$bold"
    }
  }),
  caption: _css({
    fontSize: "0.8125rem",
    color: "$gray5",
    variants: {
      completed: {
        "true": {
          color: "$gray5"
        }
      },
      active: {
        "true": {
          color: "$default"
        }
      }
    }
  }),
  header: _css(_objectSpread({}, {
    "position": "relative",
    "display": "flex",
    "alignItems": "center",
    "minHeight": "22px"
  })),
  container: _css(_objectSpread({}, {
    "position": "relative",
    "display": "grid",
    "gap": "0.5rem",
    "gridTemplateColumns": "repeat(3, 1fr)"
  })),
  item: _css(_objectSpread({}, {
    "display": "flex",
    "flexDirection": "column",
    "justifyContent": "center",
    "gap": "0.5rem"
  })),
  bar: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "height": "0.5rem",
    "width": "100%",
    "overflow": "hidden",
    "borderRadius": "0.375rem",
    "transitionDuration": "500ms",
    "animationDuration": "500ms"
  }), {}, {
    backgroundColor: "$gray3",
    variants: {
      active: {
        "true": {
          backgroundColor: "$default"
        }
      },
      completed: {
        "true": {
          backgroundColor: "$default"
        }
      },
      loading: {
        "true": _objectSpread({}, {
          "animation": "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite"
        })
      }
    }
  }))
};
export default styles;