var __jsx = React.createElement;
import React from "react";
import { useIntl } from "react-intl";
import LovaAppProductThumbnail from "/public/mobile-app-product-thumbnail.webp";
import { DummyProduct, Product } from "@/checkout/components";
import { Icon, Loader } from "@/components/ui";
import useCheckout from "@/hooks/useCheckout.hook";
import { useGlobal } from "@/hooks/useGlobal.hook";
import usePath from "@/hooks/usePath.hook";
import { cn } from "@/libraries/clsx.library";
import styles from "./List.styles";

var List = function List(_ref) {
  var lines = _ref.lines;
  var t = useIntl();

  var _useCheckout = useCheckout(),
      loading = _useCheckout.loading;

  var path = usePath();

  var _useGlobal = useGlobal(),
      lovaAppForEveryOrder = _useGlobal.lovaAppForEveryOrder;

  var hasLines = lines && lines.length > 0;
  if (loading) return __jsx("div", {
    className: cn(styles.container(), styles.loader())
  }, __jsx(Loader, null));
  if (!hasLines) return __jsx("div", {
    className: cn(styles.container(), styles.empty())
  }, __jsx(Icon, {
    name: "Cart",
    className: styles.emptyIcon()
  }), __jsx("span", {
    className: styles.emptyInfo()
  }, t.formatMessage({
    id: "cart.list.emptyInfo",
    defaultMessage: "Your shopping cart is empty"
  })));
  return __jsx("div", {
    id: "__cart-products",
    className: cn(styles.container(), styles.list())
  }, lines.map(function (line, index) {
    return __jsx(Product, {
      key: line.id,
      index: index,
      line: line,
      variant: line.variant,
      type: "CART",
      testIdPrefix: "cart-"
    });
  }), lovaAppForEveryOrder ? __jsx(DummyProduct, {
    name: t.formatMessage({
      id: "dummyProduct.lovaApp.name",
      defaultMessage: "LOVA app"
    }),
    imageSrc: LovaAppProductThumbnail,
    linkHref: path.aplikacja_lova_opowiadania_erotyczne.$url(),
    price: 0,
    priceUndiscounted: 19.99,
    detail: t.formatMessage({
      id: "dummyProduct.lovaApp.detail",
      defaultMessage: "30-day access"
    }),
    type: "CART"
  }) : null);
};

List.displayName = "List";
export default List;